import React from "react";
import { msConversion } from "../functions/functions";
import { bossHealth } from "../functions/functions";

export default function Fight(fight) {
  let bossDifficulty = ["idk", "lol", "N", "H", "M"];
  const fightDate = new Date(fight.start_time + fight.reportStart).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <React.Fragment>
      <td className="gridCenter">{fight.reportIndex}.</td>
      <td className="gridCenter-time">{fightDate}</td>
      <td className="gridCenter" style={{ maxWidth: 20 }}>
        {bossDifficulty[fight.difficulty - 1]}
        {fight.difficulty < 5 ? `(${fight.size})` : ""}
        {fight.keystoneLevel ? `+${fight.keystoneLevel}` : ""}
      </td>
      <td className="gridFightName">
        <a href={`https://www.warcraftlogs.com/reports/${fight.reportID}#fight=${fight.id}`}>
          <span title={`Raid Size: ${fight.size}`}> {memeBossName(fight)}</span>
        </a>
      </td>
      <td className="gridCenter">{percentageDisplay(fight)}</td>
      <td className="gridCenter">{msConversion(fight.end_time - fight.start_time)}</td>
    </React.Fragment>
  );
}

export function percentageDisplay(fight) {
  if (fight.kill) {
    return <b className="legendary">{fight.keystoneLevel ? "Finished" : "DEAD"}</b>;
  }
  return (
    <React.Fragment>
      {bossHealth(fight)} {fightSpecificPhaseDisplay(fight)}
    </React.Fragment>
  );
}

export function fightSpecificPhaseDisplay({ name, bossPercentage, lastPhaseForPercentageDisplay }) {
  let shouldDisplay = false;
  switch (name?.toLowerCase()) {
    case "the jailer":
      // bosshealth <= 30% HP and phase = 3 or 4
      if (lastPhaseForPercentageDisplay >= 3 && bossPercentage / 100 <= 30) {
        shouldDisplay = true;
      }
      break;
    default:
      break;
  }

  return shouldDisplay ? <React.Fragment>P{lastPhaseForPercentageDisplay}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export function memeBossName({ name }) {
  switch (name.toLowerCase()) {
    case "the vigilant steward, zskarn":
      return "Threat Level Midnight, Michael";
    default:
      return name;
  }
}
