export default async function getConfig() {
    const response = await fetch(`${window.rtApiUrl}/config`)

    if (!response.ok) {
        return Promise.resolve(null);
    }

    const data = await response.json();

    return Promise.resolve(data);
}