import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RaidTracker from "./RaidTracker.js";

const queryClient = new QueryClient();

window.isDebug = (window.location.toString()).includes('localhost')

window.rtApiUrl = window.isDebug ? 'https://localhost:7190' : 'https://api.onlytoasts.com'


export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RaidTracker />
    </QueryClientProvider>
  );
}
