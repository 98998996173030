export default async function getBestProg() {
    const response = await fetch(`${window.rtApiUrl}/warcraftlogs/best`)

    if (!response.ok) {
        return Promise.resolve(null);
    }

    const data = await response.json();

    return Promise.resolve(data);
}