import React, { useState } from "react";
import { addDays } from "./functions/functions";
import GuildRaidnight from "./components/GuildRaidnight.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import CategoryButton from "./components/CategoryButton";
import TwitchIntegration from "./components/TwitchIntegration";
import { useQuery } from "@tanstack/react-query";
import getBestProg from "./queries/getBestProg.js";
import getConfig from "./queries/getConfig.js";
import ErrorBox from "./components/ErrorBox.js";

// secret key pls don't steal
const API_KEY = "ce097b52c6a0d2d6b81d526fba53da18";

console.log("https://www.youtube.com/watch?v=6n3pFFPSlW4");

export default function RaidTracker() {
    const [startDate, setDate] = useState(window.isDebug ? new Date('01/03/2024') : new Date()); //state for datepicker
    const [mode, setMode] = useState(localStorage.getItem("mode") || "Kirby");

    useQuery({ queryKey: ['bestprog'], queryFn: getBestProg, refetchInterval: 60000 })
    const config = useQuery({ queryKey: ['config'], queryFn: getConfig, refetchInterval: 600000 })

    let guildComponents = [];
    let modeComponents = [];

    if (!mode) {
        setMode("Regular");
    }

    if (config.isSuccess && config.data) {
        let families = new Set();
        guildComponents = config?.data["guilds"].filter(guild => {
            families.add(guild.family)
            return guild.family === mode
        })
            .sort((a, b) => a.orderBy - b.orderBy)
            .map((guild) => {
                return (
                    <div className={`logpanels`} key={guild.name}>
                        <GuildRaidnight datePicked={startDate} guild={guild.name} guildURL={guild.url} isPersonal={false} api_key={API_KEY} />
                    </div>
                );
            });

        modeComponents = config?.data["families"].sort((a, b) => a.orderBy - b.orderBy).map(e => {
            return (
                <CategoryButton key={e.name} setter={setMode} label={e.name} mode={e.name} currentMode={mode} />
            )
        });
    }

    return (
        <React.Fragment>
            <div className="controlBar">
                <div className="datepickerContainer">
                    <button
                        className="datepickerButton"
                        onClick={() => {
                            setDate(addDays(startDate, -1));
                        }}
                    >
                        &#60;
                    </button>
                    <DatePicker todayButton={"Today"} selected={startDate} onChange={(date) => setDate(date)} />
                    <button className="datepickerButton" onClick={() => setDate(addDays(startDate, +1))}>
                        &#62;
                    </button>
                    <div className="mode-select-wrapper">
                        {/* <CategoryButton setter={setMode} label={"Default"} mode={"Regular"} currentMode={mode} /> */}
                        {/* <CategoryButton setter={setMode} label={"Contenders"} mode={"Kirby"} currentMode={mode} /> */}
                        {/* <CategoryButton setter={setMode} label={"Others"} mode={"Others"} currentMode={mode} /> */}
                        {/* <CategoryButton setter={setMode} label={"Custom"} mode={"Custom"} currentMode={mode} /> */}
                        {modeComponents}
                    </div>
                </div>
                <div className="hr" />
            </div>
            <div className="bodyContent">
                <div className="bgImage"></div>
                {config.isSuccess ?
                    <div className="logpanelContainer">{guildComponents}</div>
                    : config.isError ?
                        <ErrorBox text="Something terrible has happened. Tell Toastie" /> : <></>
                }
            </div>
            <TwitchIntegration />
            {/* {mode === 'Custom' ? <CustomGuildForm guilds={GUILDS} setGuilds={setGuilds} /> : <React.Fragment></React.Fragment>} */}
        </React.Fragment>
    );
}
